

















  import { Vue, Component } from "vue-property-decorator";

@Component
  export default class DataQuality extends Vue {
  $route!: any;
  }
